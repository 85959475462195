import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import ScrollLock from "cic-scroll-lock";
import classNames from "classnames";
import Images from "./Images";
import Captions from "./Captions";
import SecondaryCaptions from "./SecondaryCaptions";
import Caption from "./Caption";
import Slideshow from "../Slideshow";

export default class ImageCallout extends PureComponent {
  static displayName = "Content.ImageCallout";

  static propTypes = {
    modal: PropTypes.bool,
    slides: PropTypes.array,
    heading: PropTypes.string,
    body: PropTypes.string,
    linkText: PropTypes.string,
    customHeight: PropTypes.string,
  };

  constructor(props) {
    super();
    this.scrollLock = new ScrollLock();

    this.state = {
      active: 0,
      slideshowOpen: false,
    };
  }

  get showCaptions() {
    let show = false;

    this.props.slides.forEach((slide) => {
      if (slide.heading?.length || slide.body?.trim().length) show = true;
    });

    return show;
  }

  get showSecondaryCaptions() {
    let show = false;

    this.props.slides.forEach((slide) => {
      if (
        slide.secondary_heading?.length ||
        slide.secondary_body?.trim().length
      )
        show = true;
    });

    return show;
  }

  maybeOpenSlideshow = () => {
    if (this.props.modal) {
      this.disableScroll();
      this.setState({
        slideshowOpen: true,
      });
    }
  };

  closeSlideshow = () => {
    this.enableScroll();
    this.setState({
      slideshowOpen: false,
    });
  };

  disableScroll = () => {
    const slideShowEl = ReactDOM.findDOMNode(this);
    document.body.classList.add("no-scroll");
    this.scrollLock.only(slideShowEl);
  };

  enableScroll = () => {
    const slideShowEl = ReactDOM.findDOMNode(this);
    document.body.classList.remove("no-scroll");
    this.scrollLock.any(slideShowEl);
  };

  paginatePrev = (event, length) => {
    event.stopPropagation();
    const prev = this.state.active > 0 ? this.state.active - 1 : length - 1;
    this.setState({
      active: prev,
    });
  };

  paginateNext = (event, length) => {
    event.stopPropagation();
    const next = this.state.active + 1 < length ? this.state.active + 1 : 0;
    this.setState({
      active: next,
    });
  };

  setActive = (active) => {
    this.setState({ active });
  };

  renderPagination() {
    const slides = this.props.slides;
    return (
      <div className="pagination" aria-hidden>
        <button
          className="prev"
          onClick={(e) => {
            this.paginatePrev(e, slides.length);
          }}
        />
        <div className="ordinals">
          <div className="wrapper">
            <span className="current">{this.state.active + 1}</span>
            <span className="divider">/</span>
            <span className="last">{slides.length}</span>
          </div>
        </div>
        <button
          className="next"
          onClick={(e) => {
            this.paginateNext(e, slides.length);
          }}
        />
      </div>
    );
  }

  render() {
    const active = this.state.active;
    const { slides, modal, label, heading, body, linkText, customHeight } =
      this.props;
    const caption = { modal, label, heading, body, linkText };

    return (
      <div>
        <div
          className={classNames({
            "callout-primary": true,
            "callout-primary--with-secondary-captions":
              !this.showCaptions && this.showSecondaryCaptions,
            "callout-primary--with-multiple-captions":
              this.showCaptions && this.showSecondaryCaptions,
          })}
          style={{ minHeight: customHeight ? customHeight + "px" : null }}
        >
          <div className="media slider">
            <Images slides={slides} active={active} />
          </div>
          {(this.showCaptions || modal) && (
            <div className="captions">
              {/* Modal slideshows only have one caption */}
              {modal && (
                <Caption
                  data={caption}
                  onClick={() => this.maybeOpenSlideshow()}
                />
              )}
              {/* Non modal slideshows have a slider of multiple captions */}
              {!modal && this.showCaptions && (
                <Captions slides={slides} active={active} />
              )}
              {slides.length > 1 && this.renderPagination()}
            </div>
          )}
          {!modal && this.showSecondaryCaptions && (
            <div className="secondary-captions">
              <SecondaryCaptions slides={slides} active={active} />
              {slides.length > 1 &&
                !this.showCaptions &&
                this.renderPagination()}
            </div>
          )}
        </div>
        <ReactCSSTransitionGroup
          transitionName="fade"
          transitionEnterTimeout={100}
          transitionLeaveTimeout={350}
        >
          {modal && this.state.slideshowOpen && (
            <Slideshow
              slides={slides}
              contentType="callout"
              active={active}
              heading={heading}
              onUpdate={this.setActive}
              onClose={this.closeSlideshow}
            />
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
