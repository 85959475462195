import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class ImageCalloutImages extends PureComponent {
  static displayName = "Content.ImageCallout.Images";

  static propTypes = {
    slides: PropTypes.array,
    active: PropTypes.number,
  };

  renderImages(slides) {
    return slides.map((slide, index) => {
      const alt = slide.alt ? slide.alt : slide.caption;
      return (
        <li
          key={index}
          className="slide"
          style={{
            width: `${100 / slides.length}%`,
          }}
        >
          <figure
            style={{
              backgroundImage: `url("${slide.image}")`,
            }}
          >
            {alt ? <span className="screen-reader-text">{alt}</span> : null}
          </figure>
        </li>
      );
    });
  }

  render() {
    const { slides, active } = this.props;

    return (
      <ul
        className="slides"
        style={{
          width: `${100 * slides.length}%`,
          transform: `translateX(-${(100 / slides.length) * active}%)`,
        }}
      >
        {this.renderImages(slides)}
      </ul>
    );
  }
}
